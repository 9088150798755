<template>
  <div class="apply5">
    <van-nav-bar
      title="资料提交"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="success_icon">
      <van-icon size="40" color="#00C000" name="checked" />
    </div>
    <van-cell-group :border="false">
      <van-cell title-class="biaoti" :border="false" title="资料已经提交 我们将尽快为您审核 请耐心等待" />
    </van-cell-group>
    <div class="service_code">
      <van-cell :border="false">
        <template #title>
          <div class="title_text">如有问题请联系客服</div>
          <van-image
            style="width: 50%; height: auto;"
            lazy-load
            src="https://img.midbest.cn/uploads/kefu/17.jpg?t=1"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </template>
      </van-cell>
    </div>
    
    <div class="btn">
      <van-button block type="danger" @click="commition">确定</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "GonghuoApply5",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      radio: "1",
      shareid: 0,
      merchid: 0,
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "资料提交");
  },
  methods: {
    commition() {
      this.$router.push({ name: "Myhome", query: { shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>
<style lang="less">
.apply5 {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
  background-color: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .success_icon {
    margin-top: 10%;
  }
  .van-cell-group {
    padding: 0;
    .van-cell {
      .van-cell__title {
        flex: 1;
      }
      .biaoti {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        padding: 20px 16px;
        line-height: 40px;
      }
      .content {
        font-size: 14px;
        color: #333;
        text-align: left;
      }
    }
  }
  .paytype {
    padding: 16px;
    overflow: hidden;
    .van-radio-group {
      padding: 30px;
      background-color: #ededed;
      border-radius: 2px;
      .van-cell-group {
        overflow: hidden;
        border-radius: 4px;
        .van-cell {
          font-size: 12px;
          line-height: 30px;
          .van-cell__title{
            flex: 1;
            text-align: left;
          }
          .van-icon{
            line-height: 30px;
            margin-right: 10px;
          }
          .van-radio__icon {
            .van-icon {
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .service_code {
    overflow: hidden;
    .van-cell {
      padding: 0 20px;
      overflow: hidden;
      .van-cell__title {
        flex: 1;
      }
    }
    .title_text {
      width: 100%;
      font-size: 14px;
    }
  }
  .btn {
    padding: 20px 20px 70px;
    overflow: hidden;
    .van-button {
      margin-top: 20px;
    }
  }
}
</style>